import React, { Component } from "react";
import firebase from "../firebase";

import Section from "../comps/Section";
import SectionGall from "../comps/SectionGall";
import DotGrid from "../comps/grid";
import Gallery from "../comps/Gallery";

import Banner from "../comps/Banner";
// import ServiceTxt from "../comps/Section/ServText";
import RendrTxt from "../comps/Section/RenderTxt";
import ServiceMdia from "../comps/Section/Media";

import RendrImg from "../media/images/RenderImg.png";
import RenderBanner from "../media/images/RenderBanner.png";

const db = firebase.firestore();

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { projects: [] };
  }

  // componentDidMount() {
  //   let projectsRef = db.collection("renderings");
  //   window.scrollTo(0, 0);
  //   projectsRef.get().then(doc => {
  //     let projects = [];
  //     doc.forEach(project => {
  //       let projObj = {
  //         id: project.id,
  //         data: project.data()
  //       };
  //       projects.push(projObj);
  //     });
  //
  //     this.setState({
  //       projects
  //     });
  //   });
  // }
  componentDidMount() {
    let projectsRef = db.collection("renderings");
    window.scrollTo(0, 0);

    projectsRef.get().then((doc) => {
      let order = [];
      let projects = {};
      this.pathFilter();
      doc.forEach((project) => {
        if (project.id === "--STATS--") return (order = project.data().order);
        projects[project.id] = project.data();
      });

      this.orderProjects(projects, order);
    });
  }
  orderProjects = (prjectObj, orderArr) => {
    let projects = [];
    orderArr.forEach((id) => {
      let projObj = {
        id: id,
        data: prjectObj[id],
      };

      projects.push(projObj);
    });
    this.setState({ projects });
    this.pathFilter();
  };
  pathFilter() {
    const currentPath = this.props.location.pathname;
    const type = currentPath.replace("/rendering", "").replace("/", "");
    this.setState({ TypeFilter: type });
  }

  projectSort = () => {
    console.log("sorting");
  };
  render() {
    return (
      <div>
        <Banner image={RenderBanner} />
        <div className="sec-wrap">
          <Section
            lable="Render"
            text={<RendrTxt />}
            media={<ServiceMdia image={RendrImg} />}
          ></Section>

          <SectionGall
            lable="Archive"
            noLink={true}
            gallType={
              <Gallery
                rendering={true}
                array={this.state.projects}
                type={this.state.TypeFilter}
                srch={this.state.srchFilt}
                view={this.state.view}
              />
            }
          />

          <div className="grid-wrapper flex-cent">
            <div className="spacing" style={{ height: "100%" }}>
              <DotGrid top="12%" left="-66px" />
              <DotGrid top="32%" right="-66px" />
              <DotGrid top="60%" left="-66px" />
              <DotGrid top="80%" right="-66px" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

// <SectionGall
//   lable="Archive"
//   noLink={true}
//   items={this.state.projects}
// />
