import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD6trbnJYGcK3iRaEXUAqMJ1cvQPL2OGoU",
  authDomain: "wolfe-ad.firebaseapp.com",
  databaseURL: "https://wolfe-ad.firebaseio.com",
  projectId: "wolfe-ad",
  storageBucket: "wolfe-ad.appspot.com",
  messagingSenderId: "83942952670",
  appId: "1:83942952670:web:781821f6217daa18e4fc4f",
  measurementId: "G-PBT5WPYY8M"
};

firebase.initializeApp(firebaseConfig);

// export const db = firebase.firestore();
export default firebase;
